const tranlations_pl = {
  "/": "/",
  "/o-nas/": "/o-nas/",
  "/kontakt/": "/kontakt/",
  "/polityka-prywatnosci/": "/polityka-prywatnosci/",
  "/regulamin/": "/regulamin/",
  "/kontakt-potwierdzenie/": "/kontakt-potwierdzenie/",
  "https://adream.pl/": "https://adream.pl/",
  "https://alingua.pl/": "https://alingua.pl/",

  "Strona główna": "Strona główna",
  Oferta: "Oferta",
  "O nas": "O nas",
  Kontakt: "Kontakt",

  Realizacja: "Realizacja",
  "Ta strona wykorzystuje pliki Cookies do poprawnego działania":
    "Ta strona wykorzystuje pliki Cookies do poprawnego działania",
  "Więcej informacji": "Więcej informacji",
  Akceptuję: "Akceptuję",
  "Godziny otwarcia": "Godziny otwarcia",
  "Poniedziałek-Piątek: 8:00 - 17:00": "Poniedziałek-Piątek: 8:00 - 17:00",
  piętro: "piętro",
  accept: "Akceptuję",

  "Napisz do nas": "Napisz do nas",
  "Imię i nazwisko": "Imię i nazwisko",
  "Nr telefonu": "Nr telefonu",
  Wiadomość: "Wiadomość",
  Akceptuję: "Akceptuje",
  regulamin: "regulamin",
  "Wyrażam zgodę na przetwarzanie danych szczególnych kategorii zawartych w przesłanych dokumentach w celu dokonania wyceny oraz zrealizowania zamówienia. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposób uniemożliwiający nam przetwarzanie danych szczególnych kategorii.":
    "Wyrażam zgodę na przetwarzanie danych szczególnych kategorii zawartych w przesłanych dokumentach w celu dokonania wyceny oraz zrealizowania zamówienia. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposób uniemożliwiający nam przetwarzanie danych szczególnych kategorii.",
  "Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymywania na ten adres e-mail materiałów ofertowych dotyczących usług oferowanych przez ALINGUA SP. Z O.O.":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymywania na ten adres e-mail materiałów ofertowych dotyczących usług oferowanych przez ALINGUA SP. Z O.O.",
  Wyślij: "Wyślij",
  "Przeciągnij plik tutaj lub wybierz z urządzenia.":
    "Przeciągnij plik tutaj lub wybierz z urządzenia.",
  "Pomyślnie załadowano plik.": "Pomyślnie załadowano plik.",
  "Nieprawidłowy typ pliku.": "Nieprawidłowy typ pliku.",
  Wysyłanie: "Wysyłanie",

  Regulamin: "Regulamin",
  "Polityka Prywatności": "Polityka Prywatności",

  "Skontaktuj się z nami": "Skontaktuj się z nami",
  "Opinie Klientów": "Opinie Klientów",
  "Zobacz, jak wyglądała współpraca z naszymi Klientami przy kilku wybranych projektach.":
    "Zobacz, jak wyglądała współpraca z naszymi Klientami przy kilku wybranych projektach.",
  "Opinia Klienta": "Opinia Klienta",
  "Opis współpracy": "Opis współpracy",
  "Zrealizowane usługi": "Zrealizowane usługi",

  "2 piętro": "2 piętro",
  Kraków: "Kraków",

  Rozwiń: "Rozwiń",
  Zwiń: "Zwiń",

  Pobierz: "Pobierz",

  "Strona nie istnieje": "Strona nie istnieje",
  "Nie znaleźliśmy strony, której szukasz":
    "Nie znaleźliśmy strony, której szukasz",
  "Na szczęście wiemy, gdzie możesz znaleźć coś interesującego:":
    "Na szczęście wiemy, gdzie możesz znaleźć coś interesującego:",
}

export default tranlations_pl
