/* eslint-disable react/jsx-no-target-blank */
import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import Logo from "assets/images/logo.svg"
import IcoFb from "assets/icons/fb.svg"

import t from "locale"

const Footer = ({ data, lang }) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 footer-column">
            <img src={Logo} alt="" />
          </div>
          <div className="col-md-2 footer-column">
            <address>
              Alingua Sp. z o.o.
              <br />
              <br />
              ul. Szlak 10/5 ({t("2 piętro", lang)})
              <br />
              31-161 {t("Kraków", lang)}
            </address>
          </div>
          <div className="col-md-2 footer-column">
            <address>
              <a href="tel:+48 884 004 934"> +48 884 004 934</a>
              <br />
              <a href="mailto:kontakt@alingua.pl">kontakt@alingua.pl</a>
              <strong>
                <a href={t("https://alingua.pl/", lang)} target="_blank">
                  www.alingua.pl
                </a>
              </strong>
            </address>
          </div>
          <div className="col-md-2 footer-column">
            <div className="footer__nav">
              <Link to={t("/o-nas/", lang)}>{t("O nas", lang)}</Link>
              <Link to={t("/kontakt/", lang)}>{t("Kontakt", lang)}</Link>
            </div>
          </div>
          <div className="col-md-2 footer-column">
            <div className="footer__nav">
              {data.map((item, index) => (
                <Link to={item.uri} key={index}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-md-2 footer-column">
            <div className="footer__social">
              <a
                href="https://www.facebook.com/tlumaczenia.alingua"
                target="_blank"
              >
                <img src={IcoFb} alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer__info">
          <p>
            Alingua Audio & Content | Copyrights &copy;{" "}
            {new Date().getFullYear()}
          </p>
          <p>
            <Link to={t("/polityka-prywatnosci/", lang)}>
              {t("Polityka Prywatności", lang)}
            </Link>{" "}
            | <Link to={t("/regulamin/", lang)}>{t("Regulamin", lang)}</Link>
          </p>
          <p>
            {t("Realizacja", lang)}:{" "}
            <a href={t("https://adream.pl/", lang)} target="_blank">
              ADream.pl
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
