import "./styles.scss"

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Logo from "assets/images/logo.svg"

import t from "locale"

const Header = ({ data, lang, translations }) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 10)
    })
  })

  useEffect(() => {
    if (!isMenuOpen) return false

    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isMenuOpen])

  return (
    <header
      className={`header${isScrolled || isMenuOpen ? " header--shrink" : ""}`}
    >
      <div className="container-fluid">
        <div className="header__wrapper">
          <Link to={t("/", lang)} className="header__logo">
            <img src={Logo} alt="" />
          </Link>
          <nav className={`header__nav ${isMenuOpen ? "active" : ""}`}>
            <span>
              {t("Oferta", lang)}
              <div>
                {data.map((item, index) => (
                  <Link to={item.uri} key={index}>
                    {item.title}
                  </Link>
                ))}
              </div>
            </span>
            <Link to={t("/o-nas/", lang)}>{t("O nas", lang)}</Link>
            <Link to={t("/kontakt/", lang)}>{t("Kontakt", lang)}</Link>
          </nav>
          {lang && (
            <span
              className={`header__switch${
                translations === undefined ? " header__switch--empty" : ""
              }`}
            >
              <img
                src={require(`assets/icons/flag-${lang}.svg`).default}
                alt={lang}
              />
              <div>
                {translations?.map((item, index) => (
                  <Link to={item.uri} key={index}>
                    <img
                      src={
                        require(`assets/icons/flag-${item?.language?.slug}.svg`)
                          .default
                      }
                      alt={item?.language?.code}
                    />
                  </Link>
                ))}
              </div>
            </span>
          )}
          <button
            aria-label="Open Menu"
            className={`hamburger hamburger--squeeze${
              isMenuOpen ? " is-active" : ""
            }`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {Array(4)
              .fill(null)
              .map((item, index) => (
                <span key={index} />
              ))}
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
