import "styles/app.scss"

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CookiesProvider, withCookies } from "react-cookie"

import { Seo, Header, Footer, CookieBaner, ContactForm } from "./components"

const Layout = ({ children, seo = {}, lang, translations }) => {
  const data = useStaticQuery(
    graphql`
      query {
        data: allWpOffer(sort: { order: ASC, fields: acfOffer___order }) {
          nodes {
            title
            uri
            slug
            language {
              code
            }
          }
        }
      }
    `
  )

  const data_link = data.data.nodes
    .filter(item => item.language.code === lang.toUpperCase())
    .map(item => ({
      ...item,
      uri: lang === "en" ? `/en/offer/${item.slug}` : item.uri,
    }))

  return (
    <CookiesProvider>
      <Seo {...seo} />
      <Header data={data_link} lang={lang} translations={translations} />
      <main>
        {children}
        <ContactForm lang={lang} />
      </main>
      <Footer data={data_link} lang={lang} />
      <CookieBaner lang={lang} />
    </CookiesProvider>
  )
}

export default withCookies(Layout)
