import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { FileUploader } from "react-drag-drop-files"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

import t from "locale"

const fileTypes = ["PDF", "DOC", "DOCX"]

const ContactForm = ({ lang }) => {
  const formID = lang === "pl" ? "8" : "454"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legalSecond, setLegalSecond] = useState(false)
  const [legalThird, setLegalThird] = useState(false)
  const [file, setFile] = useState(null)
  const [file_upload_label, setFileUploadLabel] = useState(
    t("Przeciągnij plik tutaj lub wybierz z urządzenia.", lang)
  )

  const handleChange = file => {
    setFile(file)
    setFileUploadLabel(t("Pomyślnie załadowano plik.", lang))
  }

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const resetForm = () => {
    setSend(false)
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
    setLegalFirst(false)
    setLegalSecond(false)
    setLegalThird(false)
    setFile(null)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)
    formData.set("legalSecond", legalSecond)
    formData.set("legalThird", legalThird)
    formData.set("pageurl", location)
    formData.set("file", file)

    axios({
      method: "post",
      url: `https://audio-content-wp.alingua.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        resetForm()
        navigate(t("/kontakt-potwierdzenie/", lang))
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <section className="contact-form" id="contact">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <h2>{t("Napisz do nas", lang)}</h2>
          </div>
          <div className="col-md-8">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <Input
                    placeholder={`${t("Imię i nazwisko", lang)}*`}
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    placeholder={`${t("Nr telefonu", lang)}*`}
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-5">
                  <Input
                    placeholder="E-mail*"
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <div className="file-uploader">
                    <FileUploader
                      name="file-uploader"
                      hoverTitle=" "
                      multiple={false}
                      handleChange={handleChange}
                      types={fileTypes}
                      onTypeError={() =>
                        setFileUploadLabel(t("Nieprawidłowy typ pliku.", lang))
                      }
                    >
                      <div className="file-uploader__content">
                        <span>{file_upload_label}</span>
                        <span className="file-types">
                          {fileTypes.join(", ")}
                        </span>
                      </div>
                    </FileUploader>
                  </div>
                </div>
                <div className="col-md-8">
                  <Input
                    placeholder={t("Wiadomość", lang)}
                    type="textarea"
                    id="message"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    rows="6"
                  />
                </div>
              </div>

              {file && (
                <p className="file-uploader__files">{`Plik: ${file?.name}`}</p>
              )}

              <Checkbox
                name="legalFirst"
                checked={legalFirst}
                onChange={() => setLegalFirst(!legalFirst)}
                required
              >
                {t("Akceptuję", lang)}{" "}
                <a
                  href={t("/regulamin/", lang)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("regulamin", lang)}
                </a>
                .
              </Checkbox>

              <Checkbox
                name="legalFirst"
                checked={legalSecond}
                onChange={() => setLegalSecond(!legalSecond)}
                required
              >
                {t(
                  "Wyrażam zgodę na przetwarzanie danych szczególnych kategorii zawartych w przesłanych dokumentach w celu dokonania wyceny oraz zrealizowania zamówienia. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposób uniemożliwiający nam przetwarzanie danych szczególnych kategorii.",
                  lang
                )}
              </Checkbox>

              <Checkbox
                name="legalFirst"
                checked={legalThird}
                onChange={() => setLegalThird(!legalThird)}
              >
                {t(
                  "Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymywania na ten adres e-mail materiałów ofertowych dotyczących usług oferowanych przez ALINGUA SP. Z O.O.",
                  lang
                )}
              </Checkbox>

              <div className="contact-form__cta">
                <Button
                  className="button--light-hover"
                  type="submit"
                  disabled={!legalFirst}
                >
                  {send === true
                    ? `${t("Wysyłanie", lang)}..`
                    : t("Wyślij", lang)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
