const tranlations_en = {
  "/": "/en/",
  "/o-nas/": "/en/about-us/",
  "/kontakt/": "/en/contact/",
  "/polityka-prywatnosci/": "/en/privacy-policy/",
  "/regulamin/": "/en/terms-conditions/",
  "/kontakt-potwierdzenie/": "/en/contact-confirmation/",
  "https://adream.pl/": "https://adream.pl/en/",
  "https://alingua.pl/": "https://alingua.pl/en/",

  "Skontaktuj się z nami": "Contact us",
  "Opinie Klientów": "Customers opinion",
  "Zobacz, jak wyglądała współpraca z naszymi Klientami przy kilku wybranych projektach.":
    "Check out how we worked with our clients on a few selected projects.",
  "Opinia Klienta": "The client's opinion",
  "Opis współpracy": "Our collaboration",
  "Zrealizowane usługi": "The services we provided",
  "Godziny otwarcia": "Opening hours",
  "Poniedziałek-Piątek: 8:00 - 17:00": "Monday - Friday: 8:00 a.m. – 5:00 p.m.",
  "Ta strona wykorzystuje pliki Cookies do poprawnego działania":
    "This website uses cookies to function correctly",
  "Więcej informacji": "More information",
  accept: "I accept",
  Wysyłanie: "Sending",

  "Strona główna": "Homepage",
  Oferta: "Offer",
  "O nas": "About us",
  Kontakt: "Contact",

  Regulamin: "Terms and Conditions",
  "Polityka Prywatności": "Privacy Policy",

  Realizacja: "Prepared by",
  "Napisz do nas": "Write to us",
  "Imię i nazwisko": "Full name",
  "Nr telefonu": "Phone number",
  Wiadomość: "Message",
  Akceptuję: "I accept the",
  regulamin: "Terms and Conditions",
  "Wyrażam zgodę na przetwarzanie danych szczególnych kategorii zawartych w przesłanych dokumentach w celu dokonania wyceny oraz zrealizowania zamówienia. W razie braku wyrażenia powyższej zgody możesz przesłać nam dokument zawierający tego typu dane pod warunkiem, że uprzednio dokument ten zostanie zanonimizowany w sposób uniemożliwiający nam przetwarzanie danych szczególnych kategorii.":
    "I agree to the processing of special category data contained in the documents sent for the purpose of quotation and order processing If you do not give the above consent, you may send us a document containing this type of data provided that the document is first anonymised in a way that prevents us from processing special category data.",
  "Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie adresu e-mail, w celu otrzymywania na ten adres e-mail materiałów ofertowych dotyczących usług oferowanych przez ALINGUA SP. Z O.O.":
    "I agree to the processing of my personal data such as the e-mail address I have provided, in order to receive offer materials concerning the services offered by ALINGUA SP. Z O.O.",
  Wyślij: "Send",
  "Przeciągnij plik tutaj lub wybierz z urządzenia.":
    "Drag the file here or select it from your device.",
  "Pomyślnie załadowano plik.": "You have successfully uploaded the file.",
  "Nieprawidłowy typ pliku.": "Invalid file type",

  "2 piętro": "2nd floor",
  Kraków: "Krakow",

  Rozwiń: "Expand",
  Zwiń: "Collapse",

  Pobierz: "Download",

  "Strona nie istnieje": "Page does not exist",
  "Nie znaleźliśmy strony, której szukasz":
    "We did not find the page you are looking for",
  "Na szczęście wiemy, gdzie możesz znaleźć coś interesującego:":
    "Luckily, we know where you can find something of interest:",
}
export default tranlations_en
