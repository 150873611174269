import "./styles.scss"

import React, { useEffect, useState } from "react"
import { withCookies } from "react-cookie"

import Button from "components/Button"

import t from "locale"

const CookieBaner = ({ cookies, lang }) => {
  const [cookieBaner, setCookieBaner] = useState("")

  useEffect(() => {
    const isAcceptedCoookie = !!cookies.get(
      "cookie-accept-alingua-audio-content"
    )
    !isAcceptedCoookie && setCookieBaner(true)
  }, [cookies])

  const acceptCookieBaner = e => {
    e.preventDefault()
    const promiseSetCookie = new Promise(resolve =>
      resolve(
        cookies.set("cookie-accept-alingua-audio-content", "active", {
          path: "/",
        })
      )
    )
    promiseSetCookie.then(() => {
      setCookieBaner(false)
    })
  }

  return (
    <div className={`cookie-baner${cookieBaner ? " cookie-baner--open" : ""}`}>
      <p>
        {t(
          "Ta strona wykorzystuje pliki Cookies do poprawnego działania",
          lang
        )}
        .{" "}
        <a
          href={t("/polityka-prywatnosci/", lang)}
          target="_blank"
          rel="noreferrer"
        >
          {t("Więcej informacji", lang)}
        </a>
      </p>
      <Button onClick={e => acceptCookieBaner(e)}>{t("accept", lang)}</Button>
    </div>
  )
}

export default withCookies(CookieBaner)
